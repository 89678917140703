import React, { Component } from "react";
import Person from "./Person/Person";

class Persons extends Component {
  componentDidMount() {
    console.log("[Persons.js] componentDidMount");
  }

  componentWillUnmount() {
    console.log("[Persons.js] componentWillUnmount");
  }

  componentDidUpdate() {
    console.log("[Persons.js] componentDidUpdate");
  }

  render() {
    console.log("[Persons.js] render()");

    return this.props.persons.map((person, index) => {
      return (
        <Person
          key={person.id}
          style={this.props.style}
          name={person.name}
          age={person.age}
          clickHandler={() => this.props.clicked(index)}
          changeHandler={(event) => this.props.changed(event, person.id)}
        />
      );
    });
  }
}

export default Persons;

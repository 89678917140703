import React, { Component } from "react";
import css from "./Person.module.css";

class Person extends Component {
  componentDidMount() {
    console.log("[Person.js] componentDidMount");
  }

  componentWillUnmount() {
    console.log("[Person.js] componentWillUnmount");
  }

  componentDidUpdate() {
    console.log("[Person.js] componentDidUpdate");
  }

  render() {
    console.log("[Person.js] render()");

    return (
      <div className={css.Person}>
        <p onClick={this.props.clickHandler}>
          I'm a person named {this.props.name}, age {this.props.age}!
        </p>
        <input
          type="text"
          onChange={this.props.changeHandler}
          value={this.props.name}
        />
      </div>
    );
  }
}

export default Person;

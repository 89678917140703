import React, { Component } from "react";
import Persons from "../components/Persons/Persons";
import Cockpit from "../components/Cockpit/Cockpit";
import css from "./App.module.css";

class App extends Component {
  state = {
    persons: [
      { id: "fggfk", name: "Jim", age: 51 },
      { id: "rfffr", name: "Max", age: 28 },
      { id: "33rr2", name: "Manu", age: 29 },
      { id: "frq32", name: "Stephanie", age: 26 },
      { id: "fg43q", name: "Karen", age: 19 },
    ],
    showPersonsState: true,
  };

  componentWillMount() {
    console.log("[App.js] componentWillMount");
  }

  componentDidMount() {
    console.log("[App.js] componentDidMount");
  }

  componentWillUnmount() {
    console.log("[App.js] componentWillUnmount");
  }

  componentDidUnmount() {
    console.log("[App.js] componentDidMount");
  }

  render() {
    console.log("[App.js] render()");

    // const [personsState, setPersonsState] = useState([
    //   { id: "fggfk", name: "Jim", age: 51 },
    //   { id: "rfffr", name: "Max", age: 28 },
    //   { id: "33rr2", name: "Manu", age: 29 },
    //   { id: "frq32", name: "Stephanie", age: 26 },
    //   { id: "fg43q", name: "Karen", age: 19 },
    // ]);

    // const [showPersonsState, setShowPersonsState] = useState(true);

    const togglePersonsHandler = () => {
      this.setState({ showPersonsState: !this.state.showPersonsState });
    };

    const deletePersonHandler = (personIndex) => {
      const persons = [...this.state.persons];
      persons.splice(personIndex, 1);
      this.setState({ persons: persons });
    };

    const changePersonHandler = (event, id) => {
      const personIndex = this.state.persons.findIndex((p) => {
        return p.id === id;
      });
      const person = { ...this.state.persons[personIndex] };
      const persons = [...this.state.persons];
      person.name = event.target.value;
      persons[personIndex] = person;
      this.setState({ persons: persons });
    };

    let persons = null;

    if (this.state.showPersonsState) {
      persons = (
        <Persons
          persons={this.state.persons}
          clicked={deletePersonHandler}
          changed={changePersonHandler}
        />
      );
    }

    return (
      <div className={css.App}>
        <Cockpit
          showPersons={this.state.showPersonsState}
          persons={this.state.persons}
          toggle={togglePersonsHandler}
        />
        {persons}
      </div>
    );
  }
}

export default App;

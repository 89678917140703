import React, { Component } from "react";
import css from "./Cockpit.module.css";

class Cockpit extends Component {
  componentDidMount() {
    console.log("[Cockpit.js] componentDidMount");
  }

  componentWillUnmount() {
    console.log("[Cockpit.js] componentWillUnmount");
  }

  componentDidUpdate() {
    console.log("[Cockpit.js] componentDidUpdate");
  }

  render() {
    console.log("[Cockpit.js] render()");

    const classes = [];

    if (this.props.persons.length < 3) {
      classes.push(css.red);
    }
    if (this.props.persons.length < 2) {
      classes.push(css.bold);
    }

    const buttonClass = [];
    if (this.props.showPersons) {
      buttonClass.push(css.Red);
    }

    return (
      <div className={css.Cockpit}>
        <h1>Hi, I'm a React App!</h1>
        <p className={classes.join(" ")}>This is really working!</p>
        <button className={buttonClass.join(" ")} onClick={this.props.toggle}>
          Toggle people
        </button>
      </div>
    );
  }
}

export default Cockpit;
